import { IDataProvider } from '@modirkit/ui-core'

export const orderService = (dataProvider: IDataProvider) => ({
  list: async (searchModel: any) => {
    return dataProvider
      .post('rada/orders/list', { data: searchModel })
      .then((result) => {
        return result
      })
  },
  getById: async (id: number) => {
    return dataProvider.get(`rada/orders/${id}`).then((result) => {
      return result
    })
  },
  invoicePdf: async (id: number) => {
    return dataProvider.get(`rada/orders/pdfInvoice/${id}`).then((result) => {
      return result
    })
  },
  create: async (entity: any) => {
    return dataProvider
      .post('rada/orders/add', { data: entity })
      .then((result) => {
        return result
      })
  },
  update: async (entity: any) => {
    return dataProvider
      .post('rada/orders/update', { data: entity })
      .then((result) => {
        return result
      })
  },
  delete: async (id: number) => {
    return dataProvider.delete(`rada/orders/${id}`).then((result) => {
      return result
    })
  },
})
