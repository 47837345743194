import React, { Fragment, useState, useMemo, useCallback, useRef } from 'react'
import { useDataProvider } from '@modirkit/ui-core'
import { radaService } from '../../services/rada.service'
import { NavLink } from 'react-router-dom'
import { Actionbar, Breadcrumb, DataTable, Drawer, Panel } from '@modirkit/core'

import { PlusIcon } from '@modirkit/icons'
import ServiceEditor from './ServiceEditor'
const ServiceList = (props: any) => {
  const dataProvider = useDataProvider()
  const _radaService = radaService(dataProvider)
  const [data, setData] = useState<any[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [totalCount, setTotalCount] = useState<number>(1)
  const [serviceItem, setServiceItem] = useState<number>()
  const [showDrawer, setShowDrawer] = useState<boolean>(false)
  const dtRef = useRef<any>()
  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    setLoading(true)
    _radaService
      .list({ length: pageSize, start: pageSize * pageIndex })
      .then((result: any) => {
        if (result) {
          let items = result.Data.Data as any
          items.map((x: any) => {
            x.CreatedOn = new Date(x.CreatedOn)
            return x
          })

          setTotalCount(result.Data.RecordsTotal)
          setData(items)
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  const columns = useMemo(
    () => [
      {
        Header: 'Name',
        accessor: 'Name',

        Cell: ({ cell: { value } }: any) => <strong>{value}</strong>,
      },
      {
        Header: 'Price',
        accessor: 'Price',
        width: 150,
        Cell: ({ cell: { value } }: any) => (
          <Fragment>
            {
              // moment(value).format('D/MM/YYYY, h:mm a')
              (value as number).toLocaleString()
            }
          </Fragment>
        ),
      },
      {
        Header: 'Action',
        accessor: 'Id',
        width: 150,
        Cell: ({ cell: { value } }) => (
          <button
            type="button"
            className="btn btn-primary btn-sm"
            onClick={() => {
              setServiceItem(value)
              setShowDrawer(true)
            }}
          >
            Edit
          </button>
        ),
      },
    ],
    [],
  )

  return (
    <Fragment>
      <Panel.Header>
        <Actionbar>
          <Actionbar.Primary>
            <Breadcrumb>
              <Breadcrumb.Item as={NavLink} to="">
                Services
              </Breadcrumb.Item>
            </Breadcrumb>
          </Actionbar.Primary>
          <Actionbar.Tertiary>
            <button
              className="btn btn-success btn-sm"
              type="button"
              onClick={() => {
                setServiceItem(0)
                setShowDrawer(true)
              }}
            >
              <PlusIcon /> Add New Service
            </button>
          </Actionbar.Tertiary>
        </Actionbar>
      </Panel.Header>
      <Panel.Body>
        <DataTable
          ref={dtRef}
          mode="Compact"
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          totalCount={totalCount}
        />
      </Panel.Body>
      <Drawer
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false)
        }}
        backdrop="static"
        size="md"
      >
        <ServiceEditor
          id={serviceItem}
          handleClose={(reload: boolean) => {
            setShowDrawer(false)
            if (reload) {
              fetchData({
                pageIndex: 0,
                pageSize: dtRef.current.state.pageSize,
              })
            }
          }}
        />
      </Drawer>
    </Fragment>
  )
}
export default ServiceList
