import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm, useFieldArray, Control, useWatch } from "react-hook-form";
import { useDataProvider } from "@modirkit/ui-app";
import { Drawer, Loader } from "@modirkit/core";
import { orderService } from "../../services/order.service";
import { ErrorMessage } from "@hookform/error-message";
import { CrossIcon } from "@modirkit/icons";
import { CustomerPicker } from "@modirpro/customers";
import { toast } from "react-toastify";
import moment from "moment";
import RadaServicePicker from "../../components/RadaServicePicker";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { Button, DatePicker, Space } from "antd";
import { PlusOutlined } from "@ant-design/icons";
type orderData = {
  Id: number;
  OrderDate: string;
  CustomerId?: number;
  PickupDate: string;
  Items: orderItemData[];
  Payments: paymentData[];
  Tax: number;
};

type orderItemData = {
  Id: number;
  ServiceId: number;
  Price: number;
  Quantity: number;
  Description: string;
  ListOrder: number;
  Total: number;
};

type paymentData = {
  Id: number;
  Description: string;
  Amount: number;
  PaymentDate: string;
  PaymentMethodId: number;
};

const RadaOrderEditor = (props: any) => {
  const { id, handleClose } = props;
  const dataProvider = useDataProvider();
  const _orderService = orderService(dataProvider);
  const [loading, setLoading] = useState<boolean>(false);
  const [orderItem, setOrderItem] = useState<any>();
  const [subTotal, seTSubtotal] = useState<number>(0);
  const [modal, setModal] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm<orderData>();

  const { fields, append, remove } = useFieldArray({
    control,
    name: "Items",
  });

  const {
    fields: pFields,
    append: pAppend,
    remove: pRemove,
  } = useFieldArray({
    control,
    name: "Payments",
  });
  useEffect(() => {
    setLoading(true);
    register("CustomerId", { required: "This field is required" });
    register("OrderDate");
    register("PickupDate");
    if (id > 0) {
      Promise.all([getOrder()])
        .then(([_order]) => {
          setOrderItem(_order);

          _order.Items.map((item: orderItemData) => append(item));

          _order.Payments.map((payment: paymentData) => pAppend(payment));

          setValue("CustomerId", _order.CustomerId);

          setValue("OrderDate", _order.OrderDate);

          setValue("PickupDate", _order.PickupDate);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      Promise.all([getOrder()])
        .then(([_order]) => {
          setOrderItem(_order);

          append({ Id: 0 });

          setValue("OrderDate", _order.OrderDate);
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [id]);

  const getOrder = (): Promise<any> => {
    return new Promise((resolve, reject) => {
      _orderService
        .getById(id)
        .then((result) => {
          if (result) {
            if (result.Success) {
              resolve(result.Data);
              // setOrderItem(result.Data)
            } else {
              reject(result.Message);
            }
          }
        })
        .catch((error) => {
          reject(error.Message);
          setOrderItem({});
        });
    });
  };

  const onSubmit = handleSubmit((data) => {
    setLoading(true);

    if (data.Id > 0) {
      _orderService
        .update(data)
        .then((result) => {
          if (result) {
            if (result.Success) {
              toast.success(result.Message);
              handleClose(true);
            } else {
              toast.error(result.Message);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      _orderService
        .create(data)
        .then((result) => {
          if (result) {
            if (result.Success) {
              toast.success(result.Message);
              handleClose(true);
            } else {
              toast.error(result.Message);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  const handleAddRow = () => {
    const item = { Id: 0 };
    append(item);
  };
  const handleAddPaymentRow = () => {
    const item = {
      Id: 0,
    };
    pAppend(item);
  };
  const handleRemoveRow = (item: orderItemData) => {};

  const SubTotal = ({ control }: { control: Control<orderData> }) => {
    const formValues = useWatch({
      name: "Items",
      control,
    });
    const total = formValues.reduce(
      (acc, current) => acc + 1 * (current.Total || 0),
      0
    );
    const tax = +getValues("Tax");
    seTSubtotal(total + tax);
    return <p>$ {total}</p>;
  };

  const Total = ({ control }: { control: Control<orderData> }) => {
    const tax = +useWatch({
      name: "Tax",
      control,
    });
    var items = getValues("Items");
    const total = items.reduce(
      (acc, current) => acc + 1 * (current.Total || 0),
      0
    );
    return <p>$ {total + tax}</p>;
  };

  const Remanning = ({ control }: { control: Control<orderData> }) => {
    const payments = useWatch({
      name: "Payments",
      control,
    });
    const tax = useWatch({
      name: "Tax",
      control,
    });
    var items = getValues("Items");
    const totalPayments = payments.reduce(
      (acc, current) => acc + 1 * (current.Amount || 0),
      0
    );
    const subtotal = items.reduce(
      (acc, current) => acc + 1 * (current.Total || 0),
      0
    );
    const total = subtotal + +tax;
    return <p>$ {total - totalPayments}</p>;
  };

  const TotalPaid = ({ control }: { control: Control<orderData> }) => {
    const payments = useWatch({
      name: "Payments",
      control,
    });

    var items = getValues("Items");
    const totalPayments = payments.reduce(
      (acc, current) => acc + 1 * (current.Amount || 0),
      0
    );
    const total = items.reduce(
      (acc, current) => acc + 1 * (current.Total || 0),
      0
    );

    return <p>$ {totalPayments}</p>;
  };
  const toggle = () => setModal(!modal);

  const ref = useRef<any>();

  return (
    <Fragment>
      {loading && <Loader backdrop vertical content="Loading..." />}
      {orderItem && (
        <Fragment>
          <Drawer.Header>
            <Drawer.Title>
              {id > 0 && `Edit Order : ${orderItem.Code}`}
              {id <= 0 && "Add Order"}
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <form id="orderEditorForm" noValidate={true} onSubmit={onSubmit}>
              <input
                type="hidden"
                defaultValue={orderItem.Id}
                {...register("Id", { valueAsNumber: true })}
              />

              {id > 0 && (
                <div className="mb-3">
                  <label className="col-form-label text-end font-weight-bold">
                    Created On:
                  </label>
                  <label className="m-3">
                    {moment(orderItem.CreatedOn).format("D/MM/YYYY HH:MM")}
                  </label>
                </div>
              )}

              <div className="mb-3">
                <label>Order Date</label>
                <div>
                  <DatePicker
                    onChange={(date) => {
                      if (date) {
                        setValue("OrderDate", date.format("D MMM YYYY"));
                      } else {
                        setValue("OrderDate", "");
                      }
                    }}
                    format="ddd, D MMM YYYY"
                    defaultValue={moment(orderItem.OrderDate)}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="OrderDate"
                    render={({ message }: any) => (
                      <div className="invalid-feedback d-block">{message}</div>
                    )}
                  />
                </div>
              </div>

              <div className="mb-3">
                <label>Pickup Date</label>
                <div>
                  <DatePicker
                    onChange={(date) => {
                      if (date) {
                        setValue("PickupDate", date.format("D MMM YYYY"));
                      } else {
                        setValue("PickupDate", "");
                      }
                    }}
                    format="ddd, D MMM YYYY"
                    defaultValue={
                      orderItem.PickupDate == null
                        ? (null as any)
                        : moment(orderItem.PickupDate)
                    }
                  />
                </div>
              </div>

              <div className="mb-4 ">
                <label className="col-form-label text-end">Customer:</label>
                <CustomerPicker
                  id={orderItem.CustomerId}
                  onChange={(customer: any) => {
                    if (customer) {
                      setValue("CustomerId", customer.Id);
                    } else {
                      setValue("CustomerId", null as any);
                    }
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="CustomerId"
                  render={({ message }: any) => (
                    <div className="invalid-feedback d-block">{message}</div>
                  )}
                />
              </div>
              <br />
              <div className="card rounded-0 mb-4">
                <div className="card-header">
                  <Space>
                    <h6 className="mb-0">Services</h6>
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={handleAddRow}
                    />
                  </Space>
                </div>
                <div className="panel-body p-0">
                  <table className="table table-bordered table-hover m-0">
                    <colgroup>
                      <col width="50" />
                      <col width="200" />
                      <col width="150" />
                      <col />
                      <col width="120" />
                    </colgroup>
                    <thead>
                      <tr>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">Description</th>
                        <th scope="col">Fee</th>
                        <th scope="col">Note</th>
                        <th scope="col">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map((item: orderItemData, index: number) => (
                        <tr key={index}>
                          <td>
                            <input
                              type="hidden"
                              className="form-control"
                              {...register(`Items.${index}.Id`)}
                            />
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => remove(index)}
                            >
                              <CrossIcon />
                            </button>
                          </td>
                          <td>
                            <RadaServicePicker
                              id={getValues(`Items.${index}.ServiceId`)}
                              {...register(
                                `Items.${index}.ServiceId` as const,
                                {
                                  required: "This Filed is required",
                                }
                              )}
                              onChange={(service: any) => {
                                if (service) {
                                  setValue(
                                    `Items.${index}.ServiceId`,
                                    +service.Id
                                  );
                                  setValue(
                                    `Items.${index}.Price`,
                                    +service.Price
                                  );
                                  setValue(
                                    `Items.${index}.Total`,
                                    +service.Price
                                  );
                                } else {
                                  setValue(
                                    `Items.${index}.ServiceId`,
                                    null as any
                                  );
                                  setValue(`Items.${index}.Price`, null as any);
                                  setValue(`Items.${index}.Total`, null as any);
                                }
                              }}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              {...register(`Items.${index}.Price` as const)}
                              defaultValue={item.Price}
                              readOnly
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              {...register(
                                `Items.${index}.Description` as const
                              )}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              {...register(`Items.${index}.Total` as const, {
                                required: "This Filed is required",
                              })}
                              defaultValue={item.Total}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`Items.${index}.Total`}
                              render={({ message }: any) => (
                                <div className="invalid-feedback d-block">
                                  {message}
                                </div>
                              )}
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={4} className="text-end">
                          <strong>Subtotal:</strong>
                        </td>
                        <td>
                          <strong>{<SubTotal control={control} />}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="text-end">
                          <strong>GST:</strong>
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            defaultValue={orderItem.Tax}
                            {...register("Tax", {
                              required: "This Field Is Requierd",
                            })}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={4} className="text-end">
                          <strong>TOTAL:</strong>
                        </td>
                        <td>
                          <strong>{<Total control={control} />}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <br />
              <div className="card rounded-0 mt-4">
                <div className="card-header">
                  <Space>
                    <h6 className="mb-0">Payments</h6>
                    <Button
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={handleAddPaymentRow}
                    />
                  </Space>
                </div>
                <div className="card-body p-0">
                  <table className="table table-bordered table-hover m-0">
                    <colgroup>
                      <col width="50" />
                      <col width="170" />
                      <col width="150" />
                      <col width="120" />
                      <col />
                    </colgroup>
                    <thead>
                      <tr>
                        <th>&nbsp;</th>
                        <th scope="col">Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Payment Type</th>
                        <th scope="col">Note</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pFields.map((item: paymentData, index: number) => (
                        <tr key={index}>
                          <input
                            type="hidden"
                            className="form-control"
                            {...register(`Payments.${index}.Id`)}
                          />
                          <td className="text-center">
                            <button
                              type="button"
                              className="btn btn-link"
                              onClick={() => pRemove(index)}
                            >
                              <CrossIcon />
                            </button>
                          </td>
                          <td>
                            <DatePicker
                              format="D MMM YYYY"
                              defaultValue={
                                item.PaymentDate
                                  ? moment(item.PaymentDate, "D MMM YYYY")
                                  : (null as any)
                              }
                              onChange={(date: any, dateStr) => {
                                if (date) {
                                  setValue(
                                    `Payments.${index}.PaymentDate`,
                                    dateStr
                                  );
                                } else {
                                  setValue(
                                    `Payments.${index}.PaymentDate`,
                                    null as any
                                  );
                                }
                              }}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`Payments.${index}.PaymentDate`}
                              render={({ message }: any) => (
                                <div className="invalid-feedback d-block">
                                  {message}
                                </div>
                              )}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              {...register(
                                `Payments.${index}.Amount` as const,
                                {
                                  required: "This Field Is Reqiuerd",
                                }
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name={`Payments.${index}.Amount`}
                              render={({ message }: any) => (
                                <div className="invalid-feedback d-block">
                                  {message}
                                </div>
                              )}
                            />
                          </td>
                          <td>
                            <select
                              className="form-control"
                              {...register(
                                `Payments.${index}.PaymentMethodId`,
                                { required: "This Field Is Reqiuerd" }
                              )}
                            >
                              <option value="">Select..</option>
                              <option value="10">Cash</option>
                              <option value="20">EFT</option>
                            </select>
                            <ErrorMessage
                              errors={errors}
                              name={`Payments.${index}.PaymentMethodId`}
                              render={({ message }: any) => (
                                <div className="invalid-feedback d-block">
                                  {message}
                                </div>
                              )}
                            />
                          </td>
                          <td>
                            <input
                              type="text"
                              className="form-control"
                              {...register(`Payments.${index}.Description`)}
                            />
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td colSpan={2} className="text-right">
                          <strong>Total Paid:</strong>
                        </td>
                        <td colSpan={3}>
                          <strong>{<TotalPaid control={control} />}</strong>
                        </td>
                      </tr>
                      <tr>
                        <td colSpan={2} className="text-right">
                          <strong>Remaining:</strong>
                        </td>
                        <td colSpan={3}>
                          <strong>{<Remanning control={control} />}</strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </Drawer.Body>
          <Drawer.Actions>
            <button
              type="submit"
              form="orderEditorForm"
              className="btn btn-primary me-3"
            >
              Save
            </button>
            {id > 0 && (
              <button type="button" className="btn btn-danger" onClick={toggle}>
                Delete
              </button>
            )}
            <button
              type="button"
              className="btn btn-light"
              onClick={() => {
                handleClose(false);
              }}
            >
              Cancel
            </button>
          </Drawer.Actions>
          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Delete Item</ModalHeader>
            <ModalBody>Are you sure you want to delete Order?</ModalBody>
            <ModalFooter>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  _orderService.delete(id).then((result: any) => {
                    if (result && result.Success) {
                      handleClose(true);
                    }
                  });
                }}
              >
                Yes
              </button>{" "}
              <button className="btn btn-light btn-sm" onClick={toggle}>
                NO
              </button>
            </ModalFooter>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default RadaOrderEditor;
