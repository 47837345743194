import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import RadaOrderList from './RadaOrderList'

const RadaOrders = (props: any) => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path="/rada/orders/list"
          render={() => <RadaOrderList />}
        />
      </Switch>
    </Fragment>
  )
}

export default RadaOrders
