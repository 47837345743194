import React, { Fragment, useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDataProvider } from "@modirkit/ui-app";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { radaService } from "../../services/rada.service";
import { Drawer, Loader } from "@modirkit/core";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type FormData = {
  Id: number;
  Name: string;
  Description: string;
  Price: number;
};

const ServiceEditor = (props: any) => {
  const { id, handleClose } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const dataProvider = useDataProvider();
  const _radaService = radaService(dataProvider);

  const [loading, setLoading] = useState(false);
  const [serviceItem, setServiceItem] = useState<any>();

  const [modal, setModal] = useState<boolean>(false);
  const onSubmit = handleSubmit((data) => {
    setLoading(true);
    if (data.Id > 0) {
      _radaService
        .update(data)
        .then((result) => {
          if (result) {
            if (result.Success) {
              handleClose(true, result.Data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      _radaService
        .create(data)
        .then((result) => {
          if (result) {
            if (result.Success) {
              handleClose(true, result.Data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  });

  useEffect(() => {
    if (id > 0) {
      setLoading(true);
      _radaService
        .getById(id)
        .then((result) => {
          if (result) {
            if (result.Success) {
              setServiceItem(result.Data);
            }
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setServiceItem({ Id: 0 });
    }
  }, [id]);

  const toggle = () => setModal(!modal);

  return (
    <Fragment>
      {loading && <Loader backdrop vertical content="Loading..." />}
      {serviceItem && (
        <Fragment>
          <Drawer.Header>
            <Drawer.Title>
              {serviceItem.Id > 0 && `Edit Service: ${serviceItem.Name}`}
              {serviceItem.Id <= 0 && "Add Service"}
            </Drawer.Title>
          </Drawer.Header>
          <Drawer.Body>
            <form noValidate={true} id="serviceEditorForm">
              <input
                type="hidden"
                defaultValue={serviceItem.Id}
                {...register("Id")}
              />
              <div className="mb-3">
                <label className="col-form-label text-end">Name</label>
                <input
                  type="text"
                  {...register("Name", {
                    required: "This Field is Reqiuerd",
                  })}
                  className={`form-control ${
                    errors.Name && "border border-danger"
                  }`}
                  defaultValue={serviceItem.Name}
                />
                <ErrorMessage
                  errors={errors}
                  name="Name"
                  render={({ message }: any) => (
                    <div className="invalid-feedback d-block">{message}</div>
                  )}
                />
              </div>
              <div className="mb-3">
                <label className="form-labeltext-end">Price</label>

                <input
                  type="number"
                  {...register("Price", {
                    required: "This Field is Reqiuerd",
                  })}
                  defaultValue={serviceItem.Price}
                  className={`form-control ${
                    errors.Price && "border border-danger"
                  }`}
                />
                <ErrorMessage
                  errors={errors}
                  name="Price"
                  render={({ message }: any) => (
                    <div className="invalid-feedback d-block">{message}</div>
                  )}
                />
              </div>
              <div className="mb-3">
                <label className="form-label text-end">Description</label>
                <textarea
                  className="form-control"
                  {...register("Description")}
                  defaultValue={serviceItem.Description}
                />
              </div>
            </form>
          </Drawer.Body>
          <Drawer.Actions>
            <button
              type="button"
              form="serviceEditorForm"
              className="btn btn-primary me-3"
              onClick={onSubmit}
            >
              Save
            </button>
            {id > 0 && (
              <button
                type="button"
                className="btn btn-danger me-3"
                data-target="#exampleModal"
                onClick={toggle}
              >
                Delete
              </button>
            )}
            <button
              type="button"
              className="btn btn-light me-3"
              onClick={() => {
                handleClose(false);
              }}
            >
              Cancel
            </button>
          </Drawer.Actions>

          <Modal isOpen={modal} toggle={toggle}>
            <ModalHeader toggle={toggle}>Delete Item</ModalHeader>
            <ModalBody>Are you sure you want to delete Service?</ModalBody>
            <ModalFooter>
              <button
                className="btn btn-danger btn-sm"
                onClick={() => {
                  _radaService.delete(id).then((result: any) => {
                    if (result && result.Success) {
                      handleClose(true);
                    }
                  });
                }}
              >
                Yes
              </button>{" "}
              <button className="btn btn-light btn-sm" onClick={toggle}>
                NO
              </button>
            </ModalFooter>
          </Modal>
        </Fragment>
      )}
    </Fragment>
  );
};

export default ServiceEditor;
