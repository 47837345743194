import React, { createRef, Fragment, useEffect, useState } from 'react'
import { components, ControlProps, OptionProps } from 'react-select'
import AsyncSelect from 'react-select/async'
import { useDataProvider } from '@modirkit/ui-core'
import { PlusIcon } from '@modirkit/icons'
import { radaService } from '../services/rada.service'
import RadaServiceEditor from '../pages/Services/ServiceEditor'
import { Drawer, Modal, Avatar } from '@modirkit/core'

export type ServiceItem = {
  Id: number
  Name: string
  Description: string
  Price: number
  ListOrder: number
  Deleted: boolean
  IsActive: boolean
}

const RadaServicePicker = (props: any) => {
  const id = Number(props.id || 0)

  const dataProvider = useDataProvider()
  const _radaServiceService = radaService(dataProvider)

  const [options, setOptions] = useState([])
  const [services, setservices] = useState<ServiceItem[]>([])
  const [loading, setLoading] = useState(false)
  const [selectedService, setSelectedService] = useState<ServiceItem | null>()
  const [selectedOption, setSelectedOption] = useState<any>()
  const [openDrawer, setOpenDrawer] = useState(false)

  const loadServices = (inputValue: string) => {
    return new Promise<ServiceItem[]>((resolve, reject) => {
      _radaServiceService
        .list({ SearchName: inputValue, start: 0, length: 99999 })
        .then((result: any) => {
          if (result.Success) {
            setservices(result.Data.Data)
            let items: any = (result.Data.Data as any).map((x: any) => {
              return {
                value: x.Id,
                label: x.Name,
                Description: x.Description,
                Price: x.Price,
                ListOrder: x.ListOrder,
                Code: x.Code,
                Deleted: x.Deleted,
                IsActive: x.IsActive,
              }
            })
            setOptions(items)
            resolve(items)
          } else {
            reject(result.Message)
          }
        })
        .catch((err: any) => {
          reject(err.Message)
          setOptions([])
        })
    })
  }

  useEffect(() => {
    if (id > 0) {
      setLoading(true)
      _radaServiceService
        .getById(id)
        .then((result: any) => {
          if (result.Success) {
            let radaService = result.Data
            setSelectedService(radaService)
            let option: any = {
              value: radaService.Id,
              label: radaService.Name,
              Description: radaService.Description,
              Price: radaService.Price,
              ListOrder: radaService.ListOrder,
              Code: radaService.Code,
              Deleted: radaService.Deleted,
              IsActive: radaService.IsActive,
            }
            setSelectedOption(option)
          }
        })
        .finally(() => {
          setLoading(false)
        })
    }
  }, [id])

  const Option = (props: OptionProps<any>) => {
    const { children, data, innerRef, innerProps } = props

    return (
      <components.Option {...props}>
        <div className="d-flex align-items-center">
          {children}
          <div className="ms-auto">{data.Price}</div>
        </div>
      </components.Option>
    )
  }

  //TODO: using Control components causes blur issue
  const Control = (props: ControlProps<any>) => {
    const { children, ...rest } = props
    return (
      <components.Control {...rest}>
        {children}
        {selectedOption && (
          <Fragment>
            <Avatar
              name={selectedOption.Name}
              src={selectedOption.AvatarUrl}
              circle={true}
              size="xs"
              className="me-0 ms-2"
            />{' '}
            {children}
            {/* <div className="ms-auto">{selectedOption.Balance}</div> */}
          </Fragment>
        )}
        {!selectedOption && <Fragment>{children}</Fragment>}
      </components.Control>
    )
  }

  const MenuList = (menu_props: any) => {
    return (
      <div>
        <components.MenuList {...menu_props}>
          {menu_props.children}
        </components.MenuList>
        <hr className="m-0 mb-1" />
        <button
          type="button"
          className="btn btn-link btn-sm"
          onClick={() => {
            setOpenDrawer(true)
            setSelectedService(null)
          }}
        >
          <PlusIcon /> Add new Service
        </button>
      </div>
    )
  }

  const ref = createRef<any>()
  return (
    <Fragment>
      <AsyncSelect
        ref={ref}
        className="basic-single"
        classNamePrefix="select"
        name={props.name}
        isLoading={loading}
        isSearchable={true}
        isClearable={true}
        cacheOptions
        components={{ MenuList, Option }}
        loadOptions={loadServices}
        defaultOptions
        value={selectedOption}
        onChange={(selected: any) => {
          if (selected) {
            setSelectedOption(selected)
            const service = services.filter((x) => x.Id === selected.value)[0]
            if (props.onChange) {
              props.onChange(service)
            }
          } else {
            setSelectedOption(null)
            if (props.onChange) {
              props.onChange(null)
            }
          }
        }}
      />
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        backdrop="static"
        size="md"
      >
        <RadaServiceEditor
          id={selectedService?.Id ?? 0}
          handleClose={(refresh: boolean, radaService: any) => {
            setOpenDrawer(false)
            if (radaService) {
              setSelectedService(radaService)
              let option: any = {
                value: radaService.Id,
                label: radaService.Name,
                Description: radaService.Description,
                Price: radaService.Price,
                ListOrder: radaService.ListOrder,
                Code: radaService.Code,
                Deleted: radaService.Deleted,
                IsActive: radaService.IsActive,
              }
              setSelectedOption(option)
              props.onChange(radaService)
            }
          }}
        />
      </Drawer>
    </Fragment>
  )
}

export default RadaServicePicker
