import React, { Fragment, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useDataProvider } from '@modirkit/ui-app'
import { Loader } from '@modirkit/core'
import { ErrorMessage } from '@hookform/error-message'
import { ImagePicker } from '@modir/settings'

import { RadaSettingService } from '../../services/radaSettings.service'

type generalSettingData = {
  PdfSettings: pdfSettingData
}

type pdfSettingData = {
  LetterPageSizeEnabled: boolean
  LetterPageSizeEnabled_OverrideForWebsite: boolean
  LogoPictureId: number
  InvoiceCustomText1: string
  InvoiceCustomText2: string
  Width: number
  Height: number
}

const RadaSettingEditor = (props: any) => {
  const dartaProvider = useDataProvider()
  const _radaSetting = RadaSettingService(dartaProvider)
  const [dataSeting, setRadaSetting] = useState<generalSettingData>()
  const [loading, setLoading] = useState<boolean>()

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm<generalSettingData>()

  useEffect(() => {
    setLoading(true)
    _radaSetting
      .general()
      .then((result: any) => {
        if (result) {
          if (result.Success) {
            setRadaSetting(result.Data)
          }
        }
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])
  const onsubmit = handleSubmit((data: generalSettingData) => {
    setLoading(true)
    _radaSetting.save(data).then((result: any) => {
      if (result) {
        if (result.Success) {
          setLoading(false)
        }
      }
    })
  })
  return (
    <Fragment>
      {loading && <Loader backdrop vertical content="Loading..." />}
      {dataSeting && (
        <div className="card" style={{ width: '18rem;' }}>
          <div className="card-body">
            <h5 className="card-title">Rada Setting</h5>
            <form noValidate={true} onSubmit={onsubmit} id="radaSettingEditor">
              <div className="mb-3">
                <label className="col-form-label">LogoPictureId :</label>
                <ImagePicker
                  id={dataSeting.PdfSettings.LogoPictureId}
                  onChange={(value: any) => {
                    setValue('PdfSettings.LogoPictureId', value)
                  }}
                />
                <ErrorMessage
                  errors={errors}
                  name="LogoPictureId"
                  render={({ message }: any) => (
                    <div className="invalid-feedback d-block">{message}</div>
                  )}
                />
              </div>
              {dataSeting.PdfSettings.LogoPictureId > 1 ? (
                <div className="mb-3">
                  <div className="col-sm-6"></div>
                </div>
              ) : (
                ''
              )}
              <div className="mb-3">
                <label className="col-form-label">Width :</label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    {...register('PdfSettings.Width')}
                    className="form-control"
                    defaultValue={dataSeting.PdfSettings.Width}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="col-form-label">Height :</label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    {...register('PdfSettings.Height')}
                    className="form-control"
                    defaultValue={dataSeting.PdfSettings.Height}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="col-form-label">
                  LetterPageSizeEnabled :
                </label>
                <div className="col-sm-6">
                  <input
                    type="checkbox"
                    {...register('PdfSettings.LetterPageSizeEnabled')}
                    checked={dataSeting.PdfSettings.LetterPageSizeEnabled}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="col-form-label">InvoiceCustomText1 :</label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    {...register('PdfSettings.InvoiceCustomText1')}
                    className="form-control"
                    defaultValue={dataSeting.PdfSettings.InvoiceCustomText1}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="col-form-label">InvoiceCustomText2 :</label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    {...register('PdfSettings.InvoiceCustomText2')}
                    className="form-control"
                    defaultValue={dataSeting.PdfSettings.InvoiceCustomText2}
                  />
                </div>
              </div>
              <div className="mb-3">
                <label className="col-form-label">
                  LetterPageSizeEnabled :
                </label>
                <div className="col-sm-6">
                  <input
                    type="checkbox"
                    {...register(
                      'PdfSettings.LetterPageSizeEnabled_OverrideForWebsite',
                    )}
                    checked={
                      dataSeting.PdfSettings
                        .LetterPageSizeEnabled_OverrideForWebsite
                    }
                  />
                </div>
              </div>
            </form>
            <div>
              <button
                type="submit"
                className="btn btn-primary me-3"
                form="radaSettingEditor"
              >
                Save Setting
              </button>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default RadaSettingEditor
