import React, { Fragment } from "react";
const Dashboard = () => {
  return (
    <Fragment>
      <div className="p-3">
        <h1>Dashboard</h1>
        <p>Welcome to Modir</p>
      </div>
    </Fragment>
  );
};

export default Dashboard;
