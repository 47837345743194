import React, { Fragment, useCallback, useState, useMemo, useRef } from "react";
import { radaReportService } from "../../services/radaReport.service";
import { useDataProvider } from "@modirkit/ui-core";
import { Button, DatePicker } from "antd";

import {
  Actionbar,
  Breadcrumb,
  DataTable,
  Drawer,
  Panel,
} from "@modirkit/core";
import moment from "moment";
import { useForm } from "react-hook-form";
import PDFIcon from "../../assets/icons/Pdf.icon";
type searchOrderData = {
  StartDate: Date;
  EndDate: Date;
};

const PaymentsReport = () => {
  const dataProvider = useDataProvider();
  const _radaReportService = radaReportService(dataProvider);
  const currentDate = new Date(Date.now());
  const { register, handleSubmit, getValues, setValue } =
    useForm<searchOrderData>({
      defaultValues: {
        StartDate: moment(new Date()).format("D MMM YYYY") as any,
        EndDate: moment(new Date()).format("D MMM YYYY") as any,
      },
    });

  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);

  const dtRef = useRef<any>();

  const fetchData = useCallback(
    ({ pageSize, pageIndex, satrtDate, endDate }: any) => {
      setLoading(true);
      satrtDate = getValues("StartDate");
      endDate = getValues("EndDate");
      _radaReportService
        .payments({
          length: pageSize,
          start: pageIndex * pageSize,
          StartDate: satrtDate,
          EndDate: endDate,
        })
        .then((result: any) => {
          if (result) {
            let items = result.Data.Data as any;

            setTotalCount(result.Data.RecordsTotal);
            setData(items);
          }
        })
        .finally(() => {
          setLoading(false);
        });
    },
    []
  );

  const columns = useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "OrderCode",
        width: 100,
        Cell: ({ cell: { value } }: any) => <strong>{value}</strong>,
      },
      {
        Header: "Order Date",
        accessor: "OrderDate",
        width: 200,
        Cell: ({ cell: { value } }: any) => (
          <strong>{moment(value).format("D MMM YYYY")}</strong>
        ),
      },
      {
        Header: "Payment Date",
        accessor: "PaymentDate",
        width: 200,
        Cell: ({ cell: { value } }: any) => (
          <strong>{moment(value, "DD/MM/YYYY").format("D MMM YYYY")}</strong>
        ),
      },
      {
        Header: "Customer",
        accessor: "ContactFullName",
        width: 200,
        Cell: ({ cell: { value } }: any) => <strong>{value}</strong>,
        Footer: () => {
          return (
            <div className="text-end">
              <strong>Sum:</strong>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "Amount",
        width: 150,
        Cell: ({ cell: { value } }: any) => (
          <strong>{(value as number).toLocaleString()}</strong>
        ),
        Footer: (info: any) => {
          const total = React.useMemo(
            () =>
              info.rows.reduce(
                (sum: number, row: any) => row.values.Amount + sum,
                0
              ),

            [info.rows]
          );
          return <strong>${total.toLocaleString()}</strong>;
        },
      },
      {
        Header: "",
        accessor: "Id",
        width: "auto",
        Cell: ({ cell: { value } }: any) => <Fragment></Fragment>,
      },
    ],
    []
  );
  const onSubmit = handleSubmit((data: searchOrderData) => {
    fetchData({
      pageIndex: 0,
      pageSize: dtRef.current.state.pageSize,
      startDate: new Date(data.StartDate),
      endDate: new Date(data.EndDate),
    });
  });
  const downloadPaymentsReport = handleSubmit((data: searchOrderData) => {
    _radaReportService.paymentsReports(
      data.StartDate.toISOString(),
      data.EndDate.toISOString()
    );
  });

  return (
    <Fragment>
      <Panel.Header>
        <Actionbar>
          <Actionbar.Primary>
            <Breadcrumb>
              <Breadcrumb.Item>Payments Report</Breadcrumb.Item>
            </Breadcrumb>
          </Actionbar.Primary>
          <Actionbar.Tertiary>
            <button
              type="submit"
              className="btn btn-danger btn-sm"
              onClick={downloadPaymentsReport}
              formTarget="_blank"
            >
              <PDFIcon /> Export To PDF
            </button>
          </Actionbar.Tertiary>
        </Actionbar>
      </Panel.Header>
      <Panel.Body>
        <form noValidate={true} id="paymentsReportForm" onSubmit={onSubmit}>
          <div className="card m-4 rounded-0 bg-light">
            <div className="card-body">
              <fieldset>
                <div className="mb-3">
                  <div className="col-md-4">
                    <label>Start Date:</label>
                    <div>
                      <DatePicker
                        onChange={(date: any) => {
                          if (date) {
                            setValue("StartDate", date.format("D MMM YYYY"));
                          } else {
                            setValue("StartDate", null as any);
                          }
                        }}
                        format="D MMM YYYY"
                        defaultValue={moment(currentDate, "DD/MM/YYYY")}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="col-md-4">
                    <label>End Date:</label>
                    <div>
                      <DatePicker
                        onChange={(date: any) => {
                          if (date) {
                            setValue("EndDate", date.format("D MMM YYYY"));
                          } else {
                            setValue("EndDate", null as any);
                          }
                        }}
                        format="D MMM YYYY"
                        defaultValue={moment(currentDate, "DD/MM/YYYY")}
                      />
                    </div>
                  </div>
                </div>
                <Button
                  type="primary"
                  htmlType="submit"
                  form="paymentsReportForm"
                  loading={loading}
                >
                  Report
                </Button>
              </fieldset>
            </div>
          </div>
          <br />
          <DataTable
            ref={dtRef}
            mode="Compact"
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            totalCount={totalCount}
            showFooter={true}
          ></DataTable>
        </form>
      </Panel.Body>
    </Fragment>
  );
};

export default PaymentsReport;
