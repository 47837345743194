import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import ServiceList from './ServiceList'
const Services = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path="/rada/services/list"
          render={() => <ServiceList />}
        />
      </Switch>
    </Fragment>
  )
}
export default Services
