import React from "react";
import { App as ModirApp } from "@modir/app";
import customRoutes from "./routes";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import Dashboard from "./Dashboard";
import "./theme.css";

function App() {
  initializeIcons();
  return <ModirApp dashboard={Dashboard} customRoutes={customRoutes} />;
}

export default App;
