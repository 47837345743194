import React, {
  Fragment,
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from "react";
import { useForm } from "react-hook-form";
import Select from "react-select";
import jsPDF from "jspdf";
import { orderService } from "../../services/order.service";
import { useDataProvider } from "@modirkit/ui-core";
import { DatePicker, Button, Tag, Space } from "antd";
import {
  Actionbar,
  Breadcrumb,
  DataTable,
  Drawer,
  Panel,
  Avatar,
} from "@modirkit/core";

import { Link, NavLink } from "react-router-dom";
import moment from "moment";
import RadaOrderEditor from "./RadaOrderEditor";
import { PlusIcon } from "@modirkit/icons";

import { CustomerPicker } from "@modirpro/customers";
import PDFIcon from "../../assets/icons/Pdf.icon";
import OrderPrintPreview from "./orderPrintPreview";

type orderSearchModel = {
  OrderStatusIds: number[];
  CustomerId?: number;
  StartDate: Date;
  EndDate: Date;
  OrderCode: string;
};
const RadaOrderList = () => {
  const { register, handleSubmit, setValue, getValues } =
    useForm<orderSearchModel>();

  const statusOptions = [
    { Value: 0, Text: "All" },
    { Value: 10, Text: "InProgress" },
    { Value: 50, Text: "Completed" },
  ];

  const dataProvider = useDataProvider();
  const _orderService = orderService(dataProvider);

  const [data, setData] = useState<any[]>([]);
  const [totalCount, setTotalCount] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [showDrawer, setShowDrawer] = useState<boolean>(false);
  const [selectedOrder, setSelectedOrder] = useState<number>(0);
  const [statusArray, setStatusArray] = useState<number[]>([]);
  const [modal, setModal] = useState(false);
  const [orderId, setOrderId] = useState<number>(0);
  const toggle = (orderId: number) => {
    setOrderId(orderId);
    setModal(!modal);
  };
  const dtRef = useRef<any>();

  useEffect(() => {
    register("CustomerId");
    register("OrderStatusIds");
    register("StartDate");
    register("EndDate");
  });

  const fetchData = useCallback(({ pageSize, pageIndex }) => {
    setLoading(true);
    _orderService
      .list({
        length: pageSize,
        start: pageSize * pageIndex,
        StartDate: getValues("StartDate"),
        EndDate: getValues("EndDate"),
        CustomerId: getValues("CustomerId"),
        OrderStatusIds: getValues("OrderStatusIds"),
        OrderCode: getValues("OrderCode"),
      })
      .then((result: any) => {
        if (result) {
          let items = result.Data.Data as any;
          items.map((x: any) => {
            x.CreatedOn = new Date(x.CreatedOn);
            return x;
          });
          setTotalCount(result.Data.RecordsTotal);
          setData(items);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const invoicePrint = (id: number) => {
    _orderService.invoicePdf(id).then((result: any) => {
      if (result) {
        if (result.Success) {
          var doc = new jsPDF("p", "mm", [80, 203]);
          doc.addImage(
            `${result.Data.LogoPath}`,
            "jpeg",
            20,
            5,
            40,
            20,
            "Rada Fashion"
          );

          doc.setFont("helvetica");
          doc.setFontSize(11);
          doc.text("RADA FASHION", 25, 30);
          doc.text("www.RadaFashion.com.au", 15, 35);
          doc.text("ABN: 26 600 090 364", 20, 40);
          doc.text("Mobile: 0406 212 428", 20, 45);
          doc.line(0, 50, 80, 50);

          doc.text(`Order Number: ${result.Data.Order.Code}`, 5, 55);
          doc.text(`Order Date: ${result.Data.Order.OrderDateStr}`, 5, 60);
          doc.text(`Pickup Date: ${result.Data.Order.PickupDateStr}`, 5, 65);
          doc.text(`Customer: ${result.Data.Order.CustomerName}`, 5, 70);
          doc.text(`Mobile: ${result.Data.Order.Mobile}`, 5, 75);

          doc.line(0, 80, 80, 80);

          doc.text("Service", 5, 85);
          doc.text("Amount $", 50, 85);

          var y = 95;

          for (var i = 0; i < result.Data.Order.Items.length; i++) {
            if (i > 0) {
              y = y + i + 10;
            }
            doc.text(`${result.Data.Order.Items[i].ServiceName}`, 5, y);
            doc.text(`${result.Data.Order.Items[i].Total}`, 50, y);
          }
          y = y + 5;
          doc.line(0, y, 80, y);

          doc.text(`Total:`, 5, y + 7);
          doc.text(`${result.Data.Order.OrderSubtotal}`, 50, y + 7);

          //  doc.line(0, y + 40, 80, y + 10)

          doc.text(`Paid:`, 5, y + 17);
          doc.text(`${result.Data.Order.OrderReceived}`, 50, y + 17);

          // doc.line(0, y + 80, 80, y + 20)

          doc.text(`Balance:`, 5, y + 27);
          doc.text(`${result.Data.Order.OrderRemaining}`, 50, y + 27);

          doc.text("Please retain receipt for pickup", 10, y + 40);

          doc.save(`Order_${result.Data.Order.Code}.pdf`);
        }
      }
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order Number",
        accessor: "Code",
        width: "100px",
        Cell: ({ cell: { value } }: any) => (
          <div className="text-center">
            <strong>{value}</strong>
          </div>
        ),
      },
      {
        Header: "Customer",
        accessor: "CustomerName",
        width: "200px",
        Cell: ({ cell: { value } }: any) => (
          <Fragment>
            <Space>
              <Avatar name={value} size="xs" />
              <strong>{value}</strong>
            </Space>
          </Fragment>
        ),
      },
      {
        Header: "Date",
        accessor: "OrderDate",
        width: "150px",
        Cell: ({ cell: { value } }: any) => (
          <Fragment>{moment(value).format("ddd, D MMM YYYY")}</Fragment>
        ),
      },
      {
        Header: "Pickup",
        accessor: "PickupDate",
        width: "150px",
        Cell: ({ cell: { value } }: any) => (
          <strong>
            {value == null ? "_" : moment(value).format("ddd, D MMM YYYY")}
          </strong>
        ),
      },
      {
        Header: "Status",
        accessor: "StatusTitle",
        width: "120px",
        Cell: ({ cell: { value } }: any) => (
          <Fragment>
            <Tag color={value == "Completed" ? "green" : "geekblue"}>
              {value}
            </Tag>
          </Fragment>
        ),
      },
      {
        Header: "Total",
        accessor: "TotalAmount",
        width: "100px",
        Cell: ({ cell: { value } }: any) => (
          <Fragment>
            <strong>${(value as number).toLocaleString()}</strong>
          </Fragment>
        ),
      },
      {
        Header: "Balance",
        accessor: "OrderRemaining",
        width: "100px",
        Cell: ({ cell: { value } }: any) => (
          <Fragment>
            <strong className={Number(value) != 0 ? "text-danger" : ""}>
              ${(value as number).toLocaleString()}
            </strong>
          </Fragment>
        ),
      },

      {
        Header: "Action",
        accessor: "Id",
        width: "auto",

        Cell: ({ row }: any) => {
          return (
            <Fragment>
              <button
                type="button"
                className="btn btn-primary btn-sm me-2"
                onClick={() => {
                  setSelectedOrder(row.original.Id);
                  setShowDrawer(true);
                }}
              >
                Edit
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm me-2"
                onClick={() => {
                  toggle(row.original.Id);
                }}
              >
                <PDFIcon /> Print Invoice
              </button>
            </Fragment>
          );
        },
      },
    ],
    []
  );

  const onSubmit = handleSubmit((data: orderSearchModel) => {
    fetchData({
      pageIndex: 0,
      pageSize: dtRef.current.state.pageSize,
    });
  });

  return (
    <Fragment>
      <Panel.Header>
        <Actionbar>
          <Actionbar.Primary>
            <Breadcrumb>
              <Breadcrumb.Item as={NavLink} to="">
                Orders
              </Breadcrumb.Item>
            </Breadcrumb>
          </Actionbar.Primary>
          <Actionbar.Tertiary>
            <button
              className="btn btn-success btn-sm"
              type="button"
              onClick={() => {
                setSelectedOrder(0);
                setShowDrawer(true);
              }}
            >
              <PlusIcon /> Add New Order
            </button>
          </Actionbar.Tertiary>
        </Actionbar>
      </Panel.Header>
      <Panel.Body>
        <form id="searchOrderForm" onSubmit={onSubmit} noValidate={true}>
          <div className="card m-4 rounded-0 bg-light">
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <div className="col">
                      <div className="mb-2">
                        <label>Start Date:</label>
                        <div>
                          <DatePicker
                            format="DD/MM/YYYY"
                            onChange={(date: any) => {
                              if (date) {
                                setValue("StartDate", date);
                              } else {
                                setValue("StartDate", null as any);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col">
                      <div className="mb-2">
                        <label>End Date:</label>
                        <div>
                          <DatePicker
                            format="DD/MM/YYYY"
                            onChange={(date: any) => {
                              if (date) {
                                setValue("EndDate", date);
                              } else {
                                setValue("EndDate", null as any);
                              }
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mb-2">
                    <label>Customer:</label>
                    <CustomerPicker
                      onChange={(customer: any) => {
                        if (customer) {
                          setValue("CustomerId", customer.Id);
                        } else {
                          setValue("CustomerId", null as any);
                        }
                      }}
                    />
                  </div>

                  <div className="mb-2">
                    <label>Order Code:</label>
                    <div>
                      <input
                        className="form-control"
                        type="text"
                        {...register("OrderCode")}
                      />
                    </div>
                  </div>
                  <div className="mb-2">
                    <label>Order Status :</label>
                    <div>
                      <Select
                        isMulti
                        className="basic-multi-select"
                        getOptionLabel={(option: any) => option.Text}
                        getOptionValue={(option: any) => option.Value}
                        options={statusOptions}
                        onChange={(selected: any) => {
                          let statusValue = selected.map(
                            (item: any) => item.Value
                          );

                          setValue("OrderStatusIds", statusValue);
                        }}
                        defaultValue={statusOptions[0]}
                      ></Select>
                    </div>
                  </div>
                </div>
              </div>
              <Button
                type="primary"
                htmlType="submit"
                form="searchOrderForm"
                loading={loading}
              >
                Search
              </Button>
            </div>
          </div>
          <br />
          <DataTable
            ref={dtRef}
            mode="Compact"
            columns={columns}
            data={data}
            fetchData={fetchData}
            loading={loading}
            totalCount={totalCount}
          ></DataTable>
        </form>
      </Panel.Body>
      <Drawer
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
        }}
        backdrop="static"
        size="lg"
      >
        <RadaOrderEditor
          id={selectedOrder}
          handleClose={(reload: boolean) => {
            setShowDrawer(false);
            if (reload) {
              fetchData(dtRef.current.state);
            }
          }}
        />
      </Drawer>

      <OrderPrintPreview modal={modal} toggle={toggle} orderId={orderId} />
    </Fragment>
  );
};

export default RadaOrderList;
