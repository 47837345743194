import { IDataProvider } from '@modirkit/ui-core'

export const RadaSettingService = (dataProvider: IDataProvider) => ({
  general: async () => {
    return dataProvider
      .get('rada/radaSettings/getSetting', { data: {} })
      .then((result) => {
        return result
      })
  },
  save: (entity: any) => {
    return dataProvider
      .post('rada/radaSettings/save', { data: entity })
      .then((result) => {
        return result
      })
  },
})
