import { IDataProvider } from '@modirkit/ui-core'

export const radaReportService = (dataProvider: IDataProvider) => ({
  dailyReport: async (entity: any) => {
    return dataProvider
      .post('rada/RadaReports/dailyReport', { data: entity })
      .then((result) => {
        return result
      })
  },
  payments: async (entity: any) => {
    return dataProvider
      .post('rada/RadaReports/payments', { data: entity })
      .then((result) => {
        return result
      })
  },

  downloaddailyReport: async (startDate: string, endDate: string) => {
    const generateUrl = `${window.location.origin}/api/v1/en/rada/RadaReports/downloaddailyReport/${startDate}/${endDate}`

    window.open(generateUrl)

    // return dataProvider.download(
    //   `rada/RadaReports/downloaddailyReport/${startDate}/${endDate}`,
    // )
  },

  paymentsReports: async (startDate: string, endDate: string) => {
    const generateUrl = `${window.location.origin}/api/v1/en/rada/RadaReports/paymentsReports/${startDate}/${endDate}`

    window.open(generateUrl)

    // return dataProvider
    //   .get(`rada/RadaReports/downloaddailyReport/${startDate}/${endDate}`)
    //   .then((result) => {
    //     return result
    //   })
  },
})
