import React, { Fragment } from "react";
import { Switch, Route } from "react-router-dom";

import Services from "./pages/Services";
import Orders from "./pages/Orders";
import RadaSetting from "./pages/RadaSetting";
import RadaReports from "./pages/RadaReports";

const radaRoutes = [
  <Route path="/rada/services" render={() => <Services />} />,
  <Route path="/rada/orders" render={() => <Orders />} />,
  <Route path="/rada/radaSettings" render={() => <RadaSetting />} />,
  <Route path="/rada/radaReports" render={() => <RadaReports />} />,
];

export { radaRoutes };
