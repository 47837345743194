import { IDataProvider } from '@modirkit/ui-core'

export const radaService = (dataProvider: IDataProvider) => ({
  list: async (searchModel: any) => {
    return dataProvider
      .post(`rada/services/list`, { data: searchModel })
      .then((result) => {
        return result
      })
  },
  getById: async (id: number) => {
    return dataProvider.get(`rada/services/${id}`).then((result) => {
      return result
    })
  },
  create: async (entity: any) => {
    return dataProvider
      .post('rada/services/add', { data: entity })
      .then((result) => {
        return result
      })
  },
  update: async (entity: any) => {
    return dataProvider
      .post('rada/services/update', { data: entity })
      .then((result) => {
        return result
      })
  },
  delete: async (id: number) => {
    return dataProvider.delete(`rada/services/${id}`).then((result) => {
      return result
    })
  },
  getAll: async () => {
    return dataProvider.get(`rada/services/getAll`).then((result) => {
      return result
    })
  },
})
