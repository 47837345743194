import React, { forwardRef, Fragment, useEffect, useRef, useState } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import ReactToPrint from 'react-to-print'
import { orderService } from '../../services/order.service'
import { useDataProvider } from '@modirkit/ui-core'
import jsPDF from 'jspdf'

const OrderPrintPreview = (props: any) => {
  const { orderId, modal, toggle } = props
  const [invData, setInvData] = useState<any>()
  const dataProvider = useDataProvider()
  const _orderService = orderService(dataProvider)
  const printRef = useRef<any>()

  useEffect(() => {
    if (orderId > 0) {
      _orderService.invoicePdf(orderId).then((result: any) => {
        if (result.Success) {
          setInvData(result.Data)
        }
      })
    }
  }, [orderId])

  const printOrder_handler = () => {
    var doc = new jsPDF('p', 'mm', [80, 203])
    var elem: any = document.getElementById('invoice')
    doc
      .html(elem, {
        callback: (doc: any) => {
          doc.setFont('Helvetica')
          doc.save(`Order_${invData.Order.Code}.pdf`)
        },
        x: 0,
        y: -10,
        width: 80,
        windowWidth: 450,
      })
      .catch((error: any) => {
        alert(error)
      })
  }

  const Invoice = forwardRef(({ data, ...rest }: any, ref: any) => {
    return (
      <div
        style={{ margin: '0 auto', width: 400, marginTop: 100 }}
        id="invoice"
      >
        <div ref={ref} style={{ margin: 20 }}>
          <div className="text-center">
            <img src={data.LogoPath} className="img-fluid" width={150} />
          </div>
          <div className="text-center mt-2 ">
            <h6 className="fs-3">RADA FASHION</h6>
          </div>
          <div className="text-center">
            <strong className="fs-4">www.RadaFashion.com.au</strong>
          </div>
          <div className="text-center">
            <strong className="fs-4">ABN: 26 600 090 364</strong>
          </div>
          <div className="text-center">
            <strong className="fs-4">Mobile: 0406 212 428</strong>
          </div>

          <table className="table table-borderless fs-4 mt-3">
            <tr>
              <th className="text-end">Order Number:</th>
              <td>{data.Order.Code}</td>
            </tr>
            <tr>
              <th className="text-end">Order Date:</th>
              <td>{data.Order.OrderDateStr}</td>
            </tr>
            <tr>
              <th className="text-end">Pickup Date:</th>
              <td>{data.Order.PickupDateStr}</td>
            </tr>
            <tr>
              <th className="text-end">Customer:</th>
              <td>{data.Order.CustomerName}</td>
            </tr>
            <tr>
              <th className="text-end">Mobile:</th>
              <td>{data.Order.Mobile}</td>
            </tr>
          </table>

          <table className="table table-bordered fs-4 mt-3">
            <tr>
              <th>Service</th>
              <th className="text-end">Amount $</th>
            </tr>
            {data.Order.Items.map((item: any, key: number) => {
              return (
                <tr key={key}>
                  <th style={{ width: '100%' }}>
                    {item.Description ? item.Description : item.ServiceName}
                  </th>
                  <th className="text-end">{item.Total}</th>
                </tr>
              )
            })}
            <tr>
              <th>Total:</th>
              <th className="text-end">{data.Order.TotalAmount}</th>
            </tr>
            <tr>
              <th>Paid:</th>
              <th className="text-end">{data.Order.OrderReceived}</th>
            </tr>
            <tr>
              <th>Balance:</th>
              <th className="text-end">{data.Order.OrderRemaining}</th>
            </tr>
          </table>
          <br />
          <br />
          <div className="text-center fs-4">
            Please retain receipt for pickup
          </div>
        </div>
      </div>
    )
  })

  return (
    <Fragment>
      <Modal
        backdrop={false}
        fullscreen="xl"
        size="xl"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalBody>
          <div className="d-flex align-items-center border-bottom pb-2">
            <div className="flex-grow-1">
              <h5 className="m-0">Invoice preview</h5>
            </div>
            <div>
              <ReactToPrint
                trigger={() => (
                  <button className="btn btn-secondary btn-sm m-1">
                    Print
                  </button>
                )}
                content={() => printRef.current}
                pageStyle={`
            @media all {
              .page-break {
                display: none;
              }
            }
            
            @media print {
              html, body {
                height: initial !important;
                overflow: initial !important;
                -webkit-print-color-adjust: exact;
                color:black;
              }
            }
            
            @media print {
              .page-break {
                margin-top: 1rem;
                display: block;
                page-break-before: auto;
              }
            }
            
            @page {
              size: auto;
              margin: 20mm;
            }`}
              />

              <button
                className="btn btn-danger btn-sm m-1"
                onClick={() => {
                  printOrder_handler()
                  //toggle(0)
                }}
              >
                Save as PDF
              </button>
              <button
                className="btn btn-light btn-sm m-1"
                onClick={() => {
                  toggle(0)
                  setInvData(null as any)
                }}
              >
                Close
              </button>
            </div>
          </div>
          <div className="mt-5">
            {invData && <Invoice data={invData} ref={printRef} />}
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  )
}

export default OrderPrintPreview
