import React, { Fragment } from 'react'
import { Switch, Route } from 'react-router-dom'
import DailyReports from './DailyReport'
import PaymentsReport from './PaymentsReport'
const RadaReports = () => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path="/rada/radaReports/dailyReports"
          render={() => <DailyReports />}
        />
        <Route
          exact
          path="/rada/radaReports/paymentsReport"
          render={() => <PaymentsReport />}
        />
      </Switch>
    </Fragment>
  )
}

export default RadaReports
