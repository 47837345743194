import React, { Fragment } from 'react'
import { Route, Switch } from 'react-router-dom'
import RadaSettingEditor from './RadaSettingEditor'
const RadaSetting = (props: any) => {
  return (
    <Fragment>
      <Switch>
        <Route
          exact
          path="/rada/radaSettings"
          render={() => <RadaSettingEditor />}
        />
      </Switch>
    </Fragment>
  )
}

export default RadaSetting
