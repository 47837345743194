import React from "react";
import { Route } from "react-router";
import { settingsRoutes } from "@modirpro/settings";
import { customersRoutes } from "@modirpro/customers";
import { radaRoutes } from "@rada/main";

const customRoutes = [
  ...customersRoutes,
  ...settingsRoutes,
  ...radaRoutes,
  // <Route path="/settings" render={() => <Settings />} />,
];

export default customRoutes;
